<template>
  <div id="app">
    <div class="content-body">
      <!-- <transition name="slide-fade"> -->
      <keep-router-view v-if="deptId"/>
      <!-- </transition> -->
    </div>
    <transition name="el-zoom-in-bottom">
      <van-tabbar
          route
          :fixed="false"
          v-show="isShowTabbar"
          :z-index="4"
          v-model="active"
          active-color="#D61619"
          inactive-color="#212121"
      >
        <van-tabbar-item to="/">
          <span>商城</span>
          <template #icon="props">
            <img
                v-if="props.active"
                src="./assets/images/tab/home_active.png"
            />
            <img v-else src="./assets/images/tab/home.png"/>
          </template>
        </van-tabbar-item>
        <van-tabbar-item @click="znkf">
          <span>智能客服</span>
          <template #icon="props">
            <img
                v-if="props.active"
                src="./assets/images/tab/kefu.png"
            />
            <img v-else src="./assets/images/tab/kefu.png"/>
          </template>
        </van-tabbar-item>
        <van-tabbar-item to="/cart">
          <span>购物车</span>
          <template #icon="props">
            <img
                v-if="props.active"
                src="./assets/images/tab/car_active.png"
            />
            <img v-else src="./assets/images/tab/car.png"/>
          </template>
        </van-tabbar-item>
        <van-tabbar-item to="/mine">
          <span>我的</span>
          <template #icon="props">
            <img
                v-if="props.active"
                src="./assets/images/tab/mine_active.png"
            />
            <img v-else src="./assets/images/tab/mine.png"/>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </transition>
  </div>
</template>
<script>
import Vue from 'vue'
import {mapActions} from 'vuex'
import {setInfo, getInfo} from '@/util/util'
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      active: 0,
      appid: '',
      jumpAddress:{
        "order":"#/order/apiOrderList",
        "logistics":"#/order/list",
        "after":"#/afterSales/userAudit",
        "refund":"#/afterSales/userAudit",
        "shop":"#/product/overdueList",
        "index":"#/",
        "studentList":"#/student/newStudentList",
      }
    };
  },
  created() {
    this.toWx();
    localStorage.removeItem("store");
    localStorage.removeItem("selectAddress");
    localStorage.removeItem("reserveProductData");
    localStorage.removeItem("anyProductData");
    localStorage.removeItem("afterSalesOrderId");
  },
  computed: {
    isShowTabbar() {
      let showTabList = ['/', '/cart', '/mine']
      return showTabList.includes(this.$route.path)
    },
  },
  methods: {
    ...mapActions(['queryH5UserInfo', 'queryBasicConfiguration', 'queryShoppingCartNum']),
    ...mapGetters(['getStudentInfo']),
    //拿到微信code
    toWx() {
      // 授权回调
      let local = window.location.href.split("#")[0];
      // 获取上一个页面的URL
      let referrerURL = document.referrer;
      local+=`&frontURL=${referrerURL}`;
      let appid = this.GetParam(local, "appid");
      let storeId = this.GetParam(local, "storeId");
      let deliveryNoteId = this.GetParam(local, "deliveryNoteId");
      let path = this.GetParam(local, "path");
      let indexCan = true;
      //跳转门店
      if (storeId) {
        indexCan = false;
        local = local + `#/store/index/${storeId}`;
      }
      //跳转发货单页面
      if (deliveryNoteId) {
        indexCan = false;
        local = local + `#/deliveryNote/index/${deliveryNoteId}`;
      }
      //智能客服跳转  order=订单页面  logistics=物流页面  after=售后页面  refund=退款页面  shop=商品页面  index=首页 studentList=选择学生页面
      if(path !== '' || path!=='undefined'){
        indexCan = false;
        let pathSuffix = this.jumpAddress[path];
        local = local + pathSuffix;
      }
 /*     if (path === "order") {
        indexCan = false;
        local = local + `#/order/list`;
      } else if (path === "logistics") {
        indexCan = false;
        local = local + `#/order/list`;
      } else if (path === "after") {
        indexCan = false;
        local = local + `#/afterSales/userAudit`;
      } else if (path === "refund") {
        indexCan = false;
        local = local + `#/afterSales/userAudit`;
      } else if (path === "shop") {
        indexCan = false;
        local = local + `#/product/overdueList`;
      } else if (path === "index") {
        indexCan = false;
        local = local + `#/`;
      }*/

      if (indexCan) {
        // 跳转到选学生信息页面 改不做处理 2024-04-01
        local = local + `#/`;
      }

      this.appid = appid;//赋值全局变量
      //测试开关
      let testSwitch = false;
      if (testSwitch) {
        return this.testLogin();
      }
      let url =
          `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=` +
          encodeURIComponent(local) +
          `&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;

      let code = this.GetParam(window.location.href.split("#")[0], "code");

      if (!code) {
        //如果微信端没有使用过的code再次获取是一致的，所以code+1进行区分
        window.location.href = url;
      } else {
        this.code = this.GetParam(window.location.href, "code");
        this.getUserOpenId(code, appid);

      }
    },
    // 截取code
    GetParam(url, code) {
      url = url + "";
      let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
      let reg = eval(regstr);
      //eval可以将 regstr字符串转换为 正则表达式
      let result = url.match(reg);
      if (result && result[2]) {
        return result[2];
      }
    },
    getUserOpenId(code, openId) {
      let wxUserId = getInfo(this.appid + "_" + "wxUserId") || '';
      this.$http.post(this.baseAuthUrl + "/oauth/token?grant_type=mobile&mobile=H5@" + code + '@' + openId + '@' + wxUserId + '&code=' + code).then(res => {
        this.openid = res.username;
        Vue.prototype.access_token = res.access_token;
        Vue.prototype.refresh_token = res.refresh_token;
        Vue.prototype.userId = res.user_id;
        if (!wxUserId || res.user_id != wxUserId) {
          setInfo(this.appid + "_" + "wxUserId", res.user_id)
        }
        this.$forceUpdate()
      }).then(() => {
        return this.$http.get(this.baseMpUrl + "/wx_app_user/getPigxFensUserDept")
      }).then((res) => {
        //获取初始化参数
        this.queryBasicConfiguration({deptId: res.data});
        this.queryH5UserInfo();
        this.queryShoppingCartNum({userId: Vue.prototype.userId});
        Vue.prototype.deptId = res.data;
        this.$forceUpdate()
      }).catch(err => {
        console.log(err)
      });
    },
    testLogin() {
      this.$http.post(this.baseAuthUrl + "/oauth/token?grant_type=mobile&mobile=test@" + "code" + '@' + "openId" + '@' + "1770656562811772929" + '&code=' + "code").then(res => {
        this.openid = res.username;
        Vue.prototype.access_token = res.access_token;
        Vue.prototype.refresh_token = res.refresh_token;
        Vue.prototype.userId = res.user_id;
        this.$forceUpdate()
      }).then(() => {
        return this.$http.get(this.baseMpUrl + "/wx_app_user/getPigxFensUserDept")
      }).then((res) => {
        //获取初始化参数
        this.queryBasicConfiguration({deptId: res.data});
        this.queryH5UserInfo();
        this.queryShoppingCartNum({userId: Vue.prototype.userId});
        Vue.prototype.deptId = res.data;
        this.$forceUpdate()
      })
    },
    znkf() {
      let studentInfo = this.getStudentInfo();
      // 设置要跳转到的 URL
      let thisUrl = window.location.protocol + "//" + window.location.hostname;

      window.location.href = 'https://new-im.xf123.net/ai/app/guest/homePage' + `?appId=${this.appid}&type=new&sellerId=${Vue.prototype.deptId}&userId=${Vue.prototype.userId}&url=${thisUrl}&studentId=${studentInfo.id}`;

      // 在新页面中加载指定的 URL
      // window.location.href = urlToRedirect;
    },
    handlePopState() {
      // 判断是否是从其他地址跳转过来的
      const isExternalRedirect = this.isExternalRedirect();

      if (isExternalRedirect) {
        // 如果是从外部地址跳转过来的，则再回退一级
        // history.go(-1);
        // // 替换当前的历史记录，避免回退到授权页面
        // let frontURL = this.GetParam(window.location.href, "frontURL");
        // window.location.href = frontURL;
      } else {
        // 其他情况不做特殊处理
      }
    },
    isExternalRedirect() {
      // 这里可以根据你的逻辑判断是否是从外部地址跳转过来的
      // 例如，检查 URL 参数或存储的状态等
      const urlParams = new URLSearchParams(window.location.search);
      const path = urlParams.get('path');
      return !!path; // 如果有 path 参数，说明是从外部地址跳转过来的
    }
  },
  mounted() {
    window.addEventListener('popstate', this.handlePopState);
    this.handlePopState(); // 初始化时也检查一次
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handlePopState);
  },
};
</script>
<style lang="less">


@import "./assets/fonts/index.less";

.van-image {
  overflow: hidden;
}

* {
  font-family: "Source Han Sans CN";
  font-weight: 400;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


* {
  touch-action: pan-y;
}

.page-gray {
  min-height: 100%;
  background-color: #f4f4f4;
}

html, body, #app {
  height: 100%;
}

body {
  font-size: 14px;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content-body {
    transition: all .2s;
    flex: 1;
    overflow-y: auto;
  }
}

.van-popover__action, .van-popover__action-text {
  height: 38px !important;
  font-size: 12px;
}

.van-swipe__indicator.van-swipe__indicator--active {
  width: 25px;
  height: 5px;
  background: #FFFFFF;
  border-radius: 3px;
}


.van-tabs__line {
  bottom: 18px !important;
}

.tox .tox-tbtn--bespoke .tox-tbtn__select-label {
  width: 3em !important;
}

.tox-statusbar {
  display: none !important;
}

.van-checkbox__icon .van-icon {
  border-radius: 3px;
}
</style>


<style lang="less">
.slide-fade-enter-active {
  transition: all 0.8s ease;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(40px);
  opacity: 0;
}

.el-fade-in-linear-enter-active,
.el-fade-in-linear-leave-active,
.fade-in-linear-enter-active,
.fade-in-linear-leave-active {
  transition: opacity .2s linear
}

.el-fade-in-enter-active,
.el-fade-in-leave-active,
.el-zoom-in-center-enter-active,
.el-zoom-in-center-leave-active {
  transition: all .3s cubic-bezier(.55, 0, .1, 1)
}

.el-zoom-in-center-enter,
.el-zoom-in-center-leave-active {
  opacity: 0;
  transform: scaleX(0)
}

.el-zoom-in-top-enter-active,
.el-zoom-in-top-leave-active {
  opacity: 1;
  transform: scaleY(1);
  transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1);
  transform-origin: center top
}

.el-zoom-in-top-enter,
.el-zoom-in-top-leave-active {
  opacity: 0;
  transform: scaleY(0)
}

.el-zoom-in-bottom-enter-active,
.el-zoom-in-bottom-leave-active {
  opacity: 1;
  transform: scaleY(1);
  transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1);
  transform-origin: center bottom
}

.el-zoom-in-bottom-enter,
.el-zoom-in-bottom-leave-active {
  opacity: 0;
  transform: scaleY(0)
}

.el-zoom-in-left-enter-active,
.el-zoom-in-left-leave-active {
  opacity: 1;
  transform: scale(1);
  transition: transform .3s cubic-bezier(.23, 1, .32, 1), opacity .3s cubic-bezier(.23, 1, .32, 1);
  transform-origin: top left
}

.el-zoom-in-left-enter,
.el-zoom-in-left-leave-active {
  opacity: 0;
  transform: scale(.45)
}

.collapse-transition {
  transition: height .3s ease-in-out, padding-top .3s ease-in-out, padding-bottom .3s ease-in-out
}

.horizontal-collapse-transition {
  transition: width .3s ease-in-out, padding-left .3s ease-in-out, padding-right .3s ease-in-out
}

.el-list-enter-active,
.el-list-leave-active {
  transition: all 1s
}

.el-list-enter,
.el-list-leave-active {
  opacity: 0;
  transform: translateY(-30px)
}


</style>
